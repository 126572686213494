import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductGalleryContainer as SourceProductGalleryContainer,
} from 'SourceComponent/ProductGallery/ProductGallery.container';
import { RootState } from 'Util/Store/Store.type';

import {
    ProductGalleryComponentContainerPropKeys,
    ProductGalleryComponentProps,
    ProductGalleryContainerFunctions,
    ProductGalleryContainerProps,
} from './ProductGallery.type';
/** @namespace Pwa/Component/ProductGallery/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Component/ProductGallery/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({
    ...sourceMapDispatchToProps(),
    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Component/ProductGallery/Container */
export class ProductGalleryContainer extends SourceProductGalleryContainer {
    props!: ProductGalleryContainerProps;

    containerFunctions: ProductGalleryContainerFunctions = {
        onActiveImageChange: this.onActiveImageChange.bind(this),
        handleZoomChange: this.handleZoomChange.bind(this),
        disableZoom: this.disableZoom.bind(this),
        handleImageZoomPopupActiveChange: this.handleImageZoomPopupActiveChange.bind(this),
        nextElem: this.nextElem.bind(this),
        prevElem: this.prevElem.bind(this),
    };

    onActiveImageChange(activeImage?: number): void {
        this.setState({
            activeImage: activeImage !== undefined ? Math.abs(activeImage) : activeImage,
            isZoomEnabled: false,
        }, () => {
            const carouselContainer = document.querySelector('.CarouselScroll-Content') as HTMLElement | null;
            const activeItem = document.querySelector('.CarouselScrollItem_isActive') as HTMLElement | null;

            if (carouselContainer && activeItem) {
                const offset = activeItem.offsetTop - carouselContainer.offsetTop;

                carouselContainer.scrollTo({
                    top: offset,
                    behavior: 'smooth',
                });
            }
        });
    }

    nextElem() {
        const carouselContainer = document.querySelector('.CarouselScroll-Content') as HTMLElement | null;
        const activeItem = document.querySelector('.CarouselScrollItem_isActive');

        if (carouselContainer && activeItem) {
            const nextItem = activeItem.nextElementSibling as HTMLElement | null;

            if (nextItem) {
                const offset = nextItem.offsetTop - carouselContainer.offsetTop;

                carouselContainer.scrollTo({
                    top: offset,
                    behavior: 'smooth',
                });

                nextItem.click();
            }
        }
    }

    prevElem() {
        const carouselContainer = document.querySelector('.CarouselScroll-Content') as HTMLElement | null;
        const activeItem = document.querySelector('.CarouselScrollItem_isActive');

        if (carouselContainer && activeItem) {
            const prevItem = activeItem.previousElementSibling as HTMLElement | null;

            if (prevItem) {
                const offset = prevItem.offsetTop - carouselContainer.offsetTop;
                carouselContainer.scrollTo({
                    top: offset,
                    behavior: 'smooth',
                });

                prevItem.click();
            }
        }
    }

    containerProps(): Pick<ProductGalleryComponentProps, ProductGalleryComponentContainerPropKeys> {
        const { activeImage, isZoomEnabled, isImageZoomPopupActive } = this.state;
        const {
            product: { id },
            isMobile,
            isWithEmptySwitcher,
            showLoader,
            discountPercent,
        } = this.props;

        return {
            gallery: this.getGalleryPictures(),
            productName: this._getProductName(),
            activeImage,
            isZoomEnabled,
            productId: id,
            isMobile,
            isImageZoomPopupActive,
            sliderRef: this.sliderRef,
            isWithEmptySwitcher,
            showLoader,
            discountPercent,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductGalleryContainer);
