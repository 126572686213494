/* eslint-disable max-len */
import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

/** @namespace Pwa/Component/UploadIcon2/Component */
export class UploadIcon2Component extends PureComponent {
    render(): ReactElement {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19.121" viewBox="0 0 16 19.121">
                <g id="Raggruppa_175030" data-name="Raggruppa 175030" transform="translate(23889.508 20099.318)">
                    <path id="Tracciato_50972" data-name="Tracciato 50972" d="M412,14.121v3H400v-3h-2v5h16v-5Z" transform="translate(-24287.508 -20099.318)" fill="#085cf0" />
                    <path id="Tracciato_50973" data-name="Tracciato 50973" d="M405,3.828V14.121h2V3.828l3.95,3.95,1.414-1.414L406,0l-6.364,6.364,1.414,1.414Z" transform="translate(-24287.508 -20099.318)" fill="#085cf0" />
                </g>
            </svg>
        );
    }
}

export default UploadIcon2Component;
