import { ComponentType, Suspense } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import If from 'Component/If';
import Slider from 'Component/Slider';
import {
    CarouselScroll as SourceCarouselScroll,
    ProductGalleryComponent as SourceProductGalleryComponent,
    VideoPopup as SourceVideoPopup,
} from 'SourceComponent/ProductGallery/ProductGallery.component';
import { ReactElement } from 'Type/Common.type';
import { isSignedIn } from 'Util/Auth/IsSignedIn';

import { ProductGalleryComponentProps } from './ProductGallery.type';

import './ProductGallery.override.style';

// TODO: implement CarouselScroll
export const CarouselScroll = SourceCarouselScroll;

// TODO: implement VideoPopup
export const VideoPopup = SourceVideoPopup;

/** @namespace Pwa/Component/ProductGallery/Component */
export class ProductGalleryComponent extends SourceProductGalleryComponent {
    props!: ProductGalleryComponentProps;

    renderSlider(): ReactElement {
        const {
            gallery,
            activeImage,
            isZoomEnabled,
            onActiveImageChange,
            isImageZoomPopupActive,
            sliderRef,
            discountPercent,
            // isMobile,
        } = this.props;

        const mods = {
            isImageZoomPopupActive,
            isZoomInCursor: !isImageZoomPopupActive,
        };

        const isMoreThanOnePhoto = gallery.length > 1;

        return (
            <div
              ref={ this.imageRef }
              block="ProductGallery"
              elem="SliderWrapper"
              data-is-pdp
            >
                <meta itemProp="image" content={ this.getImageUrl() } />
                <If condition={ discountPercent !== '' && discountPercent !== '0' && isSignedIn() }>
                    <div block="ProductGallery" elem="Discount">
                        -
                        { discountPercent }
                        %
                    </div>
                </If>
                <Slider
                  sliderRef={ sliderRef }
                  mix={ { block: 'ProductGallery', elem: 'Slider', mods } }
                  showCounter={ false }
                  showArrows={ isMoreThanOnePhoto }
                  activeImage={ activeImage }
                  onActiveImageChange={ onActiveImageChange }
                  isInteractionDisabled={ isZoomEnabled }
                  onClick={ this.handleSliderClick }
                  sliderHeight={ isImageZoomPopupActive ? '100%' : 0 }
                  isHeightTransitionDisabledOnMount
                >
                    { /* { this.renderPlaceholderImage() } */ }
                    { gallery.map(this.renderSlide) }
                </Slider>
            </div>
        );
    }

    renderAdditionalPictures(): ReactElement {
        const {
            gallery,
            isImageZoomPopupActive,
            activeImage,
            isMobile,
            onActiveImageChange,
            nextElem,
            prevElem,
            isWithEmptySwitcher,
        } = this.props;

        const { slidesCount } = this.state;

        if (gallery.length === 1) {
            return <div block="ProductGallery" elem="Additional" mods={ { isWithEmptySwitcher } } />;
        }

        const maxItems = 4;
        const isDisabledPrev = activeImage === 0 || gallery.length < maxItems;
        const isDisabledNext = activeImage === gallery.length - 1 || gallery.length < maxItems;

        return (
            <div block="ProductGallery" elem="Additional" mods={ { isImageZoomPopupActive } }>
                <Suspense fallback={ <div /> }>
                    <If condition={ !isMobile }>
                        <button
                          onClick={ prevElem }
                          block="ScrollButtons"
                          mods={ { isPrev: true } }
                          disabled={ isDisabledPrev }
                        />
                    </If>
                    <CarouselScroll
                      activeItemId={ activeImage }
                      onChange={ onActiveImageChange }
                      showedItemCount={ slidesCount }
                      isImageZoomPopupActive={ isImageZoomPopupActive }
                    >
                        { gallery.map(this.renderAdditionalPicture.bind(this)) }
                    </CarouselScroll>
                    <If condition={ !isMobile }>
                        <button
                          onClick={ nextElem }
                          block="ScrollButtons"
                          mods={ { isNext: true } }
                          disabled={ isDisabledNext }
                        />
                    </If>
                </Suspense>
            </div>
        );
    }
}

export default withRouter(
    ProductGalleryComponent as unknown as ComponentType<
    RouteComponentProps & ProductGalleryComponentProps
    >,
);
